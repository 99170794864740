import Viewer from './components/Viewer';
import React, { useEffect, useRef, useState } from 'react';
import { datatoView as initialData } from "./scheme/ViewScheme";
import { Header } from '@linguwerk/design_kit';
import { fetchEvents, updateMarker } from './service/DB';

const DataViewer = () => {
    let [viewData, setViewData] = useState(initialData);
    let [domainSelection, setDomainSelection] = useState("dvb");

    useEffect(() => {
        const fetchData = async () => {
          try {
            const data = await fetchEvents(domainSelection);
            setViewData(data); // Assuming data is an array or the format you need
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };

        fetchData();
    }, [domainSelection]); // Run once when the component mounts

    // Updates marker in DB and changed it in viewData to update view
    async function handleMarkerChange(index: number, newMarkerValue: boolean) {
        //console.log(`Checkbox at index ${index} and event_id ${viewData[index].id} changed to ${newMarkerValue}`);
        setViewData((prevViewData) =>
            prevViewData.map((item) =>
                item.id === viewData[index].id ? { ...item, is_marked: newMarkerValue } : item
            )
        );
        // Update value in DB
        updateMarker(domainSelection, viewData[index].id, newMarkerValue);
    };

  return (
    <>
      <Header logoPath="/alphaspeech_speech_to_form.png" designElementPath="/Wellengrafik_Header.png"/>
      <Viewer data={viewData} onMarkerChange={handleMarkerChange} setDomainSelection={setDomainSelection} domainSelection={domainSelection}/>
    </>
  );
};


// fetchEvents(domainSelection).then((response: any[]) => {
//       console.log(response)
// //       if (response.Routes && response.Routes.length > 0) {
// //         let routeElements: any = [];
// //
// //         response.Routes.forEach((route: any, i: number) => {
// //           routeElements.push(
// //             <Route {...{ ...route, eventKey: i }} key={i} />
// //           )
// //         })
// //         props.setRoutes(routeElements);
// //       }
//     })

export default DataViewer;