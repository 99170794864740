// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** Header **/

#header {
    background-color: #001534;
    position: relative;
    width: 100%;
    height: 200px;
}

#header .header-content {
	width: auto;
	max-width: 100%;
}

#top_bar {
    max-width: 980px;
    padding: 75px 50px 50px 50px;
    margin: 0 auto;
}

@media only screen and (min-width: 960px) {
    #Top_bar {
        width: 200px;
        left: 125px;
    }
}

@media only screen and (max-width: 959px) {
    #Top_bar {
        width: 80%;
        margin: 0 auto;
        max-width: 400px;
    }
}

/* Logo linksbündig */
#header-design-element {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    z-index: 700;
    display: flex;
    width: 40%;
    min-width: 500px;
    height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/styles/header.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;IACI,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,aAAa;AACjB;;AAEA;CACC,WAAW;CACX,eAAe;AAChB;;AAEA;IACI,gBAAgB;IAChB,4BAA4B;IAC5B,cAAc;AAClB;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;IACf;AACJ;;AAEA;IACI;QACI,UAAU;QACV,cAAc;QACd,gBAAgB;IACpB;AACJ;;AAEA,qBAAqB;AACrB;IACI,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,UAAU;IACV,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":["/** Header **/\n\n#header {\n    background-color: #001534;\n    position: relative;\n    width: 100%;\n    height: 200px;\n}\n\n#header .header-content {\n\twidth: auto;\n\tmax-width: 100%;\n}\n\n#top_bar {\n    max-width: 980px;\n    padding: 75px 50px 50px 50px;\n    margin: 0 auto;\n}\n\n@media only screen and (min-width: 960px) {\n    #Top_bar {\n        width: 200px;\n        left: 125px;\n    }\n}\n\n@media only screen and (max-width: 959px) {\n    #Top_bar {\n        width: 80%;\n        margin: 0 auto;\n        max-width: 400px;\n    }\n}\n\n/* Logo linksbündig */\n#header-design-element {\n    content: \"\";\n    position: absolute;\n    left: 0;\n    bottom: -1px;\n    z-index: 700;\n    display: flex;\n    width: 40%;\n    min-width: 500px;\n    height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
