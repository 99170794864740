import "./App.css";
import { AlphaspeechClient, AlphaspeechClientProps, state as ASRSTATE, HypothesisToken, Message } from "@linguwerk/asr_kit";
import { getNLUResponse, markEntities } from "@linguwerk/nlu_kit";
import { Header } from '@linguwerk/design_kit';
import { Heading } from '@chakra-ui/react';
import DBForm from './components/DVBForm';
import React, { useEffect, useRef, useState } from 'react';
import { data as initialData } from "./scheme/FormScheme";
import _ from "lodash";
import { ASRButton, hypothesisTokenToString } from '../common/components/ASRButton';
import { HypothesisTextarea } from '../common/components/HypothesisTextarea';
import CONFIG from "./res/config";

const asr = new AlphaspeechClient({
    asr_url: CONFIG.asr.url,
    configuration: CONFIG.asr.rtcConf,
    jwt_token: CONFIG.asr.auth,
    domainConf: CONFIG.asr.domainConf,
    settings: {
        useTestAudioFiles: false,
        testAudioFileName: ""
    }
});

function App() {
    const [formData, setFormData] = useState(initialData);
    const [currentState, setCurrentState] = useState(0);
    const [hypothesis, setHypothesis] = useState("");
    const [isFirstTimeShowAllowAccessDialog, setIsFirstTimeShowAllowAccessDialog] = useState(true);
    const [sessionId, setSessionId] = useState("0");
    const [asrState, setAsrState] = useState(ASRSTATE.off);

    /** When formData is used in Callbacks the initial Data would be used all the time.
     * 
     * `useRef` points to the actual current Data. */
    const formDataRef: any = useRef();
    formDataRef.current = formData;
    // Because of usage as callback this needs to be a reference:
    const sessionIdRef = useRef("");
    sessionIdRef.current = sessionId;

    // Connect to ASR as soon as the page is loaded
    useEffect(() => {
        setCurrentState(ASRSTATE.connecting);
    }, [])

    // Don't change, when data is the same (Could happen otherwise, when new object with same values is given)
    function setFormDataWrapper(val: any) {
        var newData: any = _.cloneDeep(val);
        console.log("------------------------ current formData & newData to set");
        console.log(_.cloneDeep(formDataRef.current));
        console.log(newData);
        console.log("------------------------");
        if (!_.isEqual(newData, formDataRef.current) && !_.isEqual(newData, {})) {
            let returnData: any = _.cloneDeep(formDataRef.current);
            _.merge(returnData, newData);
            console.log("merged Data:", returnData);
            setFormData(_.cloneDeep(returnData));
        }
    }

    // Control states
    useEffect(() => {
        switch (currentState) {
            case ASRSTATE.connecting:
                asr.connectToASR((e: any) => { console.log(e); onHypothesis(e) }, currentState, setCurrentState, isFirstTimeShowAllowAccessDialog, setIsFirstTimeShowAllowAccessDialog);
                break;
            case ASRSTATE.disconnecting:
                asr.stopSpeechRecognition(setCurrentState);
                break;
            case ASRSTATE.unmute:
                asr.unmute(setCurrentState);
                break;
            case ASRSTATE.mute:
                asr.mute(currentState, setCurrentState);
                break;
        }
    }, [currentState])

    function convertToData() {
        let data = {
            "start": formDataRef.current.stationDep,
            "destination": formDataRef.current.stationDest,
            "date": formDataRef.current.dateDep,
            "time": formDataRef.current.timeDep,
            "isDeparture": formDataRef.current.depArr === "ab"
        }
        return data;
    }

    function onHypothesis(response: any) {
        let message: any = JSON.parse(response.data);
        let hypothesisString = hypothesisTokenToString(message.hypothesis);
        if (message.is_final === true) {
            console.log("Final hypothesis: " + hypothesisString);
            console.log("onHypothesis formData", formDataRef.current);
            getNLUResponse(hypothesisString, convertToData(), sessionIdRef.current, CONFIG.nlu.auth, CONFIG.nlu.url, CONFIG.nlu.nluConfig)
                .then(async (response: any) => {
                    if (response.status === 201) {
                        let response_data = await response.json();
                        console.log("NLU RESPONSE: ===========================");
                        console.log(response_data);
                        if (response_data.sessionId !== "0") {
                            setSessionId(response_data.sessionId);
                        }
                        if (response_data.formData !== undefined) {
                            let newFormData = createFormDictFromSlots(response_data);
                            setFormDataWrapper(newFormData);
                            // `HypothesisField` uses the `hypothesis`-state as innerHTML, `markEntities` returns html with colored intents
                            setHypothesis(markEntities(response_data));
                        }
                    } else {
                        setHypothesis(hypothesisString)
                    }
                })
        } else {
            setHypothesis(hypothesisString);
            console.log("Current hypothesis: " + hypothesisString);
        }
    }

    (window as any).fakeHypothesis = (fakeHypothesis: string) => onHypothesis(
        { data: `{"hypothesis": [{"content": "${fakeHypothesis}"}], "is_final": true}` }
    );

    return (
        <>
            <ASRButton currentState={currentState} setState={setCurrentState}></ASRButton>
            <HypothesisTextarea hypothesis={hypothesis} setHypothesis={setHypothesis} state={currentState} />
            <Header logoPath="/alphaspeech_speech_to_form.png" designElementPath="/Wellengrafik_Header.png"/>
            <div
                className="formclass col-12 col-sm-10"
                style={{ paddingLeft: "3px", paddingRight: "3px", left: "50%", transform: "translate(-50%)", position: "relative", paddingTop: "3rem", maxWidth: "1000px", paddingBottom: "120px" }}>
                <Heading as="h1" size="2xl" style={{ marginBottom: "10px" }}> Reiseauskunft </Heading>
                <DBForm data={formData} onDataChange={setFormDataWrapper} />
            </div>
        </>
    );
};

const translation: any = {
  "start": "stationDep",
  "destination": "stationDest",
  "date": "dateDep",
  "time": "timeDep"
}

/**
 * Creates a dictionary with new formData from the NLU slots response.
 * Also does some formatting to fit in the form.
 * @param nlu_response NLU response
 * @returns Updated data
 */
function createFormDictFromSlots(nlu_response: any) {
    let newData: any = {};
    let entityNames: string[] = [];
    let daytimeValue: String = "";
    console.log("nlu_response before sorting out:", nlu_response);

    nlu_response.annotationData.forEach((e: any) => {
        if ("role" in e) {
            entityNames.push(e.role);
        } else {
            entityNames.push(e.entity);
        }
    });
    console.log("ENTITY NAMES:", entityNames);

  Object.keys(nlu_response.formData).forEach((key: any) => {
    if (nlu_response.formData[key] !== null ) // && entityNames.includes(key))
    {
        if (key === "isDeparture") {
            if (nlu_response.formData[key]) {
                newData.depArr = "ab";
            }
            else {
                newData.depArr = "an";
            }
        }
        else if (key === "start" || key === "destination"){
            if (nlu_response.formData[key].name !== null && nlu_response.formData[key].name !== "") {
                newData[translation[key]] = _.cloneDeep(nlu_response.formData[key].name);
            }
        }
        else {
            newData[translation[key]] = _.cloneDeep(nlu_response.formData[key]);
        }
    }
  });
  console.log("newData after sorting out:", newData);
  // Set depArr to true if travel_departure_time not null, to false if travel_arrival_time not null
//   if ('travelArrivalTime' in newData
//   && newData.travelArrivalTime !== null) {
//     newData["depArr"] = "an"
//   }
//   else if ('travelDepartureTime' in newData
//   && newData.travelDepartureTime !== null) {
//     newData["depArr"] = "ab"
//   }

  for (let [key, value] of Object.entries(newData)) {
    let formFieldData: string = value as string;
    if (formFieldData !== undefined && formFieldData !== "" && formFieldData !== "0" && formFieldData !== null) {
      if (key === "timeDep" || key === "timeArr") {
        let time: any;
        if (!formFieldData.includes("Uhr") || formFieldData.includes("zwischen")) {
            formFieldData = convertTime(formFieldData)
        }
        if (formFieldData.includes("Uhr")) {
          time = formFieldData.split(" ")[0];
        }
        else {
          time = formFieldData;
        }
        let hours = time.split(":")[0];
        let minutes = time.split(":")[1];
        newData["timeDep"] = new Date(0, 0, 0, hours, minutes).toTimeString();

      } else if (key === "dateDep") {
        let x = formFieldData.split(".");
        let dateStr = x[2] + "-" + x[1] + "-" + x[0];
        let date = new Date(dateStr)
        newData[key] = date.toISOString().split("T")[0];
      } else if (key === "stationDep" || key === "stationDest") {
        newData[key] = {}
        newData[key].name = value;
//         newData[key_translated].city = "";
//         if (typeof value === 'string') {
//           newData[key_translated].id = "0"; //= fetchStopData(value)[0].id;
//         } else {
//           newData[key_translated].id = "0";
//         }
      } else {
        newData[key] = value;
      }
    }
  }
  console.log("HERE TEST SHOULD BE SET!")
  console.log(newData)
  return newData;
}

function convertTime(time: string): string {

    // make into time range
    if (time === "morgens") {
        time = "zwischen 07:00 Uhr und 11:00 Uhr"
    }
    if (time === "vormittags") {
        time = "zwischen 10:30 Uhr und 11:59 Uhr"
    }
    if (time === "mittags") {
        time = "12:00 Uhr"
    }
    if (time === "nachmittags") {
        time = "zwischen 12:30 Uhr und 16:59 Uhr"
    }
    if (time === "abends") {
        time = "zwischen 17:00 Uhr und 20:00 Uhr"
    }
    if (time === "nachts") {
        time = "zwischen 20:00 Uhr und 01:00 Uhr"
    }

    // handle time range
    if (time.includes("zwischen")) {
        return time.substring(9, 18)
    }
    return time
}



export default App;
