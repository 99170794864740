import { Header } from '@linguwerk/design_kit';
import React from 'react';
import { Link } from 'react-router-dom';
import './css/navigationButton.css';

function App() {

    return (
        <>
            <Header logoPath="/alphaspeech_speech_to_form.png" designElementPath="/Wellengrafik_Header.png"/>
            <div style={{ "display": "flex", "flexDirection": "column", "alignItems": "center" }}>
                <Link to="/dvb">
                    <button className="navigationButton">Go to DVB Demo</button>
                </Link>
                <Link to="/db">
                    <button className="navigationButton">Go to DB Demo</button>
                </Link>
                <Link to="/pirads">
                    <button className="navigationButton">Go to PI-RADS Demo</button>
                </Link>
            </div>
        </>
    );
};

export default App;