import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";

import "../style/Routes.css";
import { EvalDVBDate } from "../service/DVB";

import { faBus, faTrain, faTrainTram, faTrainSubway, faCableCar, faBusSimple, faPlus, faStairs, faPersonWalking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BusStartNewRoute from "../res/stripes/Bus/StartNewRoute.svg";
import BusStartMidRoute from "../res/stripes/Bus/StartMidRoute.svg";
import BusMid from "../res/stripes/Bus/Mid.svg";
import BusEndMidRoute from "../res/stripes/Bus/EndMidRoute.svg";
import BusEnd from "../res/stripes/Bus/End.svg";

import footpathStart from "../res/stripes/Footpath/Start.svg";
import footpathMid from "../res/stripes/Footpath/Mid.svg";
import footpathEnd from "../res/stripes/Footpath/End.svg";

interface MotProps {
  Type: string;
  Name: string;
  Direction: string;
}

interface PlatformProps {
  Name: string;
}

interface RegularStopProps {
  ArrivalTime: string;
  Platform: PlatformProps;
  Name: string;
}

interface PartialRouteProps { // add duration to NextDepartureTimes[0] for destTime
  Duration: number;
  Mot: MotProps;
  NextDepartureTimes: [string];
  RegularStops: [RegularStopProps];
  isFirstPartialRoute: boolean;
  isLastPartialRoute: boolean;
  depDest: "dep" | "dest";
  onlyMid: boolean;
}

export interface RouteProps {
  Duration: number;
  Interchanges: number;
  PartialRoutes: [PartialRouteProps];
  eventKey: string;
}
export default function Route(props: RouteProps) {
  return (
    <Accordion>
      <Accordion.Item eventKey={props.eventKey}>
        <RouteHeader {...props} />
        <RouteBody {...props} />
      </Accordion.Item>
    </Accordion>
  )
}

function RouteHeader(props: RouteProps) {
  const timeDep = props.PartialRoutes[0].RegularStops[0].ArrivalTime;
  const timeDepStr: string = DVBDateToString(timeDep);
  let lastPartialRouteDep: Date = EvalDVBDate(props.PartialRoutes[props.PartialRoutes.length - 1].RegularStops[0].ArrivalTime);
  const timeDestStr: string = DateToString(
    new Date(
      lastPartialRouteDep.getTime() + props.PartialRoutes[props.PartialRoutes.length - 1].Duration * 60_000
    )
  );

  const mots = GetModesOfTransports(props);
  let motList: any = [];
  mots.forEach((mot: any, i: number) => { motList.push(<Mot Type={mot.type} Name={mot.name} key={i} />) })

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  if (isMobile) {
    return (<>
      <AccordionHeader style={{ padding: "0" }}>
        <div className="container">
          <div className="row">
            <div className="col-4 start-0">
              ab {timeDepStr}
            </div>
            <div className="col-6 offset-md-1 end-0">
              {props.Duration} Minuten
            </div>
            <div className="col-2 col-md-3 transportation-name-column">
              an {timeDestStr}
            </div>
          </div>
          <div className="row">
            <div className="col-8 offset-4 offset-md-1">
              {motList}
            </div>
          </div>
          <div className="row">
            <div className="col-5 start-0">
              {props.Interchanges} {props.Interchanges === 1 ? "Umstieg" : "Umstiege"}
            </div>
          </div>
        </div>
      </AccordionHeader >
    </>)
  } else {
    return (<>
      <AccordionHeader>
        <div className="container">
          <div className="row">
            <div className="col-2 start-0">
              {timeDepStr} bis {timeDestStr}
            </div>
            <div className="col-6 end-0">
              {props.Interchanges} {props.Interchanges === 1 ? "Umstieg" : "Umstiege"}
            </div>
            <div className="col-4 transportation-name-column">
              {motList}
            </div>
          </div>
          <div className="row">
            <div className="col-2 start-0">
              {props.Duration} Minuten
            </div>
          </div>
        </div>
      </AccordionHeader >
    </>)
  }
  return (<>

  </>
  )
}

function RouteBody(props: RouteProps) {
  const partialRouteDatas: [PartialRouteProps] = props.PartialRoutes;
  let partialRoutes: any = [];

  partialRouteDatas.forEach((partialRouteData: PartialRouteProps, i: number) => {
    const isFirstPartialRoute: boolean = i === 0;
    const isLastPartialRoute: boolean = (partialRouteDatas.length - 1) === i;
    partialRoutes.push(
      <PartialRoute
        {...partialRouteData}
        isFirstPartialRoute={isFirstPartialRoute}
        isLastPartialRoute={isLastPartialRoute}
        key={i}
      />
    );
  })

  return (
    <AccordionBody>
      <div className="container">
        {partialRoutes}
      </div>
    </AccordionBody>
  )
}

function PartialRoute(props: PartialRouteProps) {
  let route: any;
  let hasRegularStops: any = props.RegularStops;
  if (props.Mot.Type === "Footpath" && !hasRegularStops) {
    route = <PartialRouteFootpath {...{ ...props, onlyMid: true }} />;
  } else if (props.Mot.Type === "Footpath" && hasRegularStops) {
    route = <PartialRouteFootpath {...{ ...props, onlyMid: false }} />;
  } else if (["MobilityStairsDown", "MobilityStairsUp", "MobilityRampDown", "MobilityRampUp"].includes(props.Mot.Type)) {
    route = <PartialRouteStairs {...props} />
  } else {
    route = <PartialRouteNoFootpath {...props} />
  }

  return (
    <div className="container">
      {route}
    </div>
  )
}

function PartialRouteStairs(props: any) {
  const backgroundImage: any = {
    backgroundRepeat: "repeat-y",
    backgroundImage: `url(${footpathMid})`,
    backgroundPosition: "16px 0",
    padding: "2.5em 0px"
  }
  var stairsText = "";
  if (props.Mot.Type === "MobilityStairsDown") { stairsText = "Treppen abwärts"; }
  else if (props.Mot.Type === "MobilityStairsUp") { stairsText = "Treppen aufwärts"; }
  else if (props.Mot.Type === "MobilityRampDown") { stairsText = "Rampe abwärts"; }
  else if (props.Mot.Type === "MobilityRampUp") { stairsText = "Rampe aufwärts"; }
  return (<>
    <div className="row align-items-center">
      <div className="offset-2 col-2 col-md-1" style={backgroundImage}>
        <FontAwesomeIcon icon={faStairs} />
      </div>
      <div className="col-8 col-md-9">
        {stairsText}
      </div>
    </div>
  </>)
}

function PartialRouteNoFootpath(props: PartialRouteProps) {
  return (
    <>
      <div className="row">
        <PartialRouteDepDest {...{ ...props, depDest: "dep" }} />
      </div>
      <div className="row">
        <PartialRouteMid {...props} />
      </div>
      <div className="row">
        <PartialRouteDepDest {...{ ...props, depDest: "dest" }} />
      </div>
    </>
  )
}

function PartialRouteFootpath(props: PartialRouteProps) {
  return (<>
    {!props.onlyMid && props.isFirstPartialRoute ? <div className="row"><FootpathStart {...props} /></div> : null}
    <div className="row align-items-center">
      <FootpathMid {...props} />
    </div>
    {!props.onlyMid && props.isLastPartialRoute ? <div className="row"><FootpathEnd {...props} /></div> : null}
  </>)
}

function FootpathStart(props: PartialRouteProps) {
  const backgroundImage: any = {
    backgroundRepeat: "repeat-y",
    backgroundImage: `url(${footpathStart})`,
    backgroundPosition: "16px 0",
    padding: "2.5em 0px",
    height: "35px"
  }
  return (<>
    <span className="offset-2 col-2 col-md-1" style={backgroundImage} />
    <div className="col-8 col-md-9">
      <div className="container">
        <div className="row"><strong style={{ padding: 0 }}>{props.RegularStops[0].Name}</strong></div>
      </div>
    </div>
  </>)
}

function FootpathMid(props: PartialRouteProps) {
  const backgroundImage: any = {
    backgroundRepeat: "repeat-y",
    backgroundImage: `url(${footpathMid})`,
    backgroundPosition: "16px 0",
    padding: "2.5em 0px"
  }
  return (<>
    <div className="offset-2 col-2 col-md-1" style={backgroundImage}>
      <FontAwesomeIcon icon={faPersonWalking}/>
    </div>
    <div className="col-8 col-md-9">
      {props.Duration} Minuten Fußweg
    </div>
  </>)
}

function FootpathEnd(props: PartialRouteProps) {
  const backgroundImage: any = {
    backgroundRepeat: "repeat-y",
    backgroundImage: `url(${footpathEnd})`,
    backgroundPosition: "16px 0",
    padding: "2.5em 0px",
    height: "35px"
  }
  return (<>
    <span className="offset-2 ccol-2 col-md-1" style={backgroundImage} />
    <div className="col-8 col-md-9">
      <div className="container">
        <div className="row"><strong style={{ padding: 0 }}>{props.RegularStops[props.RegularStops.length - 1].Name}</strong></div>
      </div>
    </div>
  </>)
}

function PartialRouteDepDest(props: PartialRouteProps) {
  let arrivalTime: string = "";
  let stopName: string = "";
  let url: any;
  let depDest: string = "";

  if (!props.hasOwnProperty("RegularStops")) {
    //console.log("Skipped for the following data, because RegularStops is not available in the following data:")
    //console.log(props)
    return <></>;
  }

  if (props.depDest === "dep") {
    arrivalTime = DVBDateToString(props.RegularStops[0].ArrivalTime);
    stopName = props.RegularStops[0].Name;
    depDest = "ab";
  } else if (props.depDest === "dest") {
    arrivalTime = DVBDateToString(props.RegularStops[props.RegularStops.length - 1].ArrivalTime);
    stopName = props.RegularStops[props.RegularStops.length - 1].Name;
    depDest = "an";
  }

  if (props.depDest === "dep" && props.isFirstPartialRoute) {
    url = BusStartNewRoute;
  } else if (props.depDest === "dep" && !props.isFirstPartialRoute) {
    url = BusStartMidRoute;
  } else if (props.depDest === "dest" && props.isLastPartialRoute) {
    url = BusEnd;
  } else if (props.depDest === "dest" && !props.isLastPartialRoute) {
    url = BusEndMidRoute;
  }

  const backgroundImage: any = {
    backgroundRepeat: "repeat-y",
    backgroundImage: `url(${url})`,
    backgroundPosition: "16px 0",
    padding: "2.5rem 0"
  }

  if (props.RegularStops[0].Platform) {
    var platform: any = <div className="row">Steig {props.RegularStops[0].Platform.Name}</div>;
  }


  return (
    <>
      <div className="col-2">
        <div className="row">
          <div className="col-1">{depDest}</div>
          <div className="col-7">{arrivalTime}</div>
        </div>
        <div className="row">
          <div className="col-8"></div>
          <div className="col-4"></div>
        </div>
      </div>
      <div className="col-2 col-md-1" style={backgroundImage}></div>
      <div className="col-8 col-md-9">
        <div className="container">
          <div className="row"><strong style={{ padding: 0 }}>{stopName}</strong></div>
          {platform ? platform : null}
        </div>
      </div>
    </>
  )
}

function PartialRouteMid(props: PartialRouteProps) {
  let url: any = BusMid;
  const backgroundImage: any = {
    backgroundRepeat: "repeat-y",
    backgroundImage: `url(${url})`,
    backgroundPosition: "16px 0",
    padding: "2.5rem 0"
  }

  return (
    <>
      <div className="offset-2 col-2 col-md-1" style={backgroundImage}></div>
      <div className="col-8 col-md-9">
        <div className="container">
          <div className="row">
            <strong className="transportation-name-column" style={{ padding: 0 }}>
              <Mot {...props.Mot} />
            </strong>
          </div>
        </div>
      </div>
    </>
  )
}

function DVBDateToString(date: string): any {
  const dateObj = EvalDVBDate(date);

  if (dateObj === null) {
    return;
  } else {
    // Get the hours and minutes from the Date object
    return DateToString(dateObj);
  }
}

function DateToString(date: Date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format the hours and minutes as "HH:mm"
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

  return formattedTime;
}

function GetModesOfTransports(data: any): [any] {
  let mot: any = [];
  data.MotChain.forEach((element: any) => {
    if (["Footpath", "MobilityStairsDown", "MobilityStairsUp", "MobilityRampDown", "MobilityRampUp"].includes(element.Type)) {
      return;
    }
    mot.push(
      {
        type: element.Type,
        name: element.Name
      }
    )
  })
  return mot;
}

function Mot({ Type, Name, Direction = null }: any) {
  let img: any;
  let color: any;
  switch (Type) {
    case "Bus":
      img = faBus;
      color = "#5a7ac4";
      break;
    case "Tram":
      img = faTrainTram;
      color = "#d3a75a";
      break;
    case "RapidTransit":
      img = faTrainSubway;
      color = "#c02121";
      break;
    case "Cablecar":
      img = faCableCar;
      color = "#3fe476";
      break;
    case "PlusBus":
      img = faBusSimple;
      color = "#913fe4";
      break;
    case "Train":
      img = faTrain;
      color = "#253756";
      break;
    // case "MobilityStairsDown":
    //   img = faStairs;
    //   color = "#ac3e8a";
    // case "MobilityStairsUp":
    //   img = faStairs;
    //   color = "#ac3e8a";
    // case "MobilityRampDown":
    //   img = faStairs;
    //   color = "#ac3e8a";
    // case "MobilityRampUp":
    //   img = faStairs;
    //   color = "#ac3e8a";
  }
  return (<>
    <FontAwesomeIcon icon={img} style={{ color: color, paddingTop: "3px", paddingRight: "3px", paddingLeft: "3px" }} />
    <span className="transportation-name">{Name}{Direction !== null ? (" " + Direction) : ""}</span>
  </>)
}