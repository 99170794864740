import general_auth from "./general_auth"

export default {
  asr: {
    url: "https://stream.alphaspeech.de",
    /**
     * rtcConf is of type RTCConfiguration:
     * {
     *    iceServers: [{urls:"...", credential:"...", username:"..."}]}
     * */
    rtcConf: {
      iceServers: [
        {urls: "turn:a.relay.metered.ca:443", credential:"y1PyHpi6B5t1ADo0", username:"08046b7d118c39a9cb0c050f"}
      ]
    },
    domainConf: {
        asr: {"domain": "de-DE_DB_VOICEBOT"},
        punctuation: {"enable": false},
        structure: {"enable": false},
        itn: {
            "enable": true,
            "convert_small_nums": true,
            "convert_datetime_structures": true,
            "convert_currency_structures": true,
            "convert_measurement_units": true
        },
    },
    auth: general_auth
  },
  nlu: {
    auth: general_auth,
    url: "https://formsnlu.alphaspeech.de/v1/nlu/",
    nluConfig: {"domain": "db", "language": "de-DE"}
  }
}
