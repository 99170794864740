import './App.css';
import '../common/css/alphaspeech.css'
import { AlphaspeechClient, AlphaspeechClientProps, state as ASRSTATE, HypothesisToken, Message, state } from "@linguwerk/asr_kit";
import { getNLUResponse, markEntities } from "@linguwerk/nlu_kit"
import { Header } from '@linguwerk/design_kit';
import DictateWithTemplate from './components/DictateWithTemplate';
import FindingArea from './components/FindingArea';
import Dictate from './components/Dictate';
import ReportButton from './components/ReportGenerator';
import { AppStateProvider } from './context/AppStateContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { data as prostateDefaultData } from "./scheme/prostate/data";
import { data as lesionListDefaultData } from "./scheme/lesions/lesionListDefaultData";
import { ASRButton, hypothesisTokenToString } from '../common/components/ASRButton';
import CONFIG from "./res/config";
import { HypothesisTextarea } from '../common/components/HypothesisTextarea';
import { SpeechCommandProvider } from './modules/SpeechCommand';
import { ELEMENT_ID } from './shared/element_ids';
import { getUpdatedText } from './modules/helperFunctions';
import './css/index.css';
import { merge } from 'lodash';
import _ from 'lodash';


const asrFormText = new AlphaspeechClient({
    asr_url: CONFIG.asr.url,
    configuration: CONFIG.asr.rtcConf,
    jwt_token: CONFIG.asr.auth,
    domainConf: CONFIG.asr.domainConfFormSpeech,
    settings: {
        useTestAudioFiles: false,
        testAudioFileName: ""
    }
});

const asrFreeText = new AlphaspeechClient({
    asr_url: CONFIG.asr.url,
    configuration: CONFIG.asr.rtcConf,
    jwt_token: CONFIG.asr.auth,
    domainConf: CONFIG.asr.domainConfFreeText,
    settings: {
        useTestAudioFiles: false,
        testAudioFileName: ""
    }
});

function extractProstateData(formData: any) {
    var returnData: any = { "prostate": undefined, "psa": undefined };
    if (formData && formData.hasOwnProperty("prostate")) {
        returnData.prostate = { ...formData.prostate }
    }
    if (formData && formData.hasOwnProperty("psa")) {
        returnData.psa = { ...formData.psa }
    }
    return returnData
}


function extractLesionsData(formData: any, lesionList: any) {
    var returnData: any = [];
    if (formData && formData.hasOwnProperty("lesions")) {
        let lesions = [...formData.lesions];
        console.log("FROM NLU FORM DATA TO APPLY:", lesions)
        for (let j = 0; j < lesions.length; j++) {
            if (!lesions[j].lesionNum) {
                // TODO: check if this check is nessecary, contextData saves the previous used lesionNum
                console.error("Received invalid data from NLU. Don't know which lesion the data belongs to.");
                return returnData;
            }
            else {
                let returnLesion = JSON.parse(JSON.stringify(lesionListDefaultData["lesions"][0])); // must do it like this to make a deep copy!
                returnLesion["lesionNum"] = lesions[j]["lesionNum"];

                trySetProperty(returnLesion, lesions, j, "size");
                trySetProperty(returnLesion, lesions, j, "t2");
                trySetProperty(returnLesion, lesions, j, "dwi");
                trySetProperty(returnLesion, lesions, j, "dce");
                trySetProperty(returnLesion, lesions, j, "pirads");
                if (lesions[j]["location"]) {
                    if (lesions[j]["location"]["level"]) {
                        let level = lesions[j]["location"]["level"];
                        let conversion: any = {
                            "Basis": "Base",
                            "mittig": "Mid",
                            "Apex": "Apex"
                        };
                        returnLesion.level = conversion[level] ? conversion[level] : level;
                    }
                    if (lesions[j]["location"]["side"]) {
                        returnLesion.side = lesions[j]["location"]["side"].toLowerCase() === "rechts" ? "R" : "L";
                    }
                    if (lesions[j]["location"]["zone"]) {
                        returnLesion.zone = lesions[j]["location"]["zone"];
                    }
                }
                returnData.push(returnLesion);
            }
        }
    }

    //const newLesionList = merge({}, lesionList, {"lesions": returnData});
    //console.log(newLesionList)
    return { "lesions": returnData }

    function trySetProperty(obj: any, lesions: any, j: number, prop: string) {
        if (lesions[j][prop]) {
            if (prop === "t2" || prop === "dwi" || prop === "pirads") {
                obj[prop] = String(lesions[j][prop]);
            }
            else {
                obj[prop] = lesions[j][prop];
            }
        }
    }
}

function App() {
    const [asrToUse, setAsrToUse]: ["freeText" | "formText", any] = useState("freeText");
    const asrToUseRef = useRef("");
    asrToUseRef.current = asrToUse;

    const [technology, setTechnology] = useState("");
    const [medicalHistory, setMedicalHistory] = useState("");
    const [prostateData, setProstateData] = useState(prostateDefaultData);
    const prostateDataRef = useRef(prostateDefaultData);
    prostateDataRef.current = prostateData;
    //const [psaData, setPsaData] = useState(psaDefaultData);
    const [PIRADS, setPIRADS] = useState("not defined");
    const PIRADSRef = useRef("not defined");
    PIRADSRef.current = PIRADS;
    const [lesionList, setLesionList] = useState(lesionListDefaultData);
    const lesionListRef = useRef(lesionListDefaultData);
    lesionListRef.current = lesionList;
    const [remarks, setRemarks] = useState("");
    const [summary, setSummary] = useState("");

    const [asrState, setAsrState] = useState(ASRSTATE.off);
    const [hypothesis, setHypothesis] = useState("");
    const [asrFocus, setAsrFocus] = useState(ELEMENT_ID.none);
    const [speechCommandsCreated, setSpeechCommandsCreated] = useState(false);
    const [speechCommandProvider, setSpeechCommandProvider] = useState(new SpeechCommandProvider());
    const asrFocusRef = useRef("");
    asrFocusRef.current = asrFocus;
    const [focusLesion, setFocusLesion] = useState(0);

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [doReset, setDoReset] = useState(false);
    const [textAreaCursorPosition, setTextAreaCursorPosition] = useState(0);
    const textAreaCursorPositionRef = useRef(textAreaCursorPosition);

    const [sessionId, setSessionId] = useState("0");
    const sessionIdRef = useRef(sessionId);
    sessionIdRef.current = sessionId;

    function setActiveLesion(activeLesionNum: number) {
        console.warn("FUNCTION 'setActiveLesion' NOT IMPLEMENTED");
    }

    function dictate(newData: string, setData: any) {
        setData((prevData: string) => {

            let result:string = getUpdatedText(prevData, textAreaCursorPositionRef, newData)
            return result;
        });
    }

    function setFocus(id: string, scrollIntoView: boolean) {
        if(id === ELEMENT_ID.none) {
            document.querySelectorAll(".dictateTo").forEach(el => {
                el.classList.remove("dictateTo");
            })
            return;
        }

        const el = document.getElementById(id);
        if (!el) {
            console.warn("Element not found. Can't focus.");
            return;
        }
        else {
            document.querySelectorAll(".dictateTo").forEach(el => {
                el.classList.remove("dictateTo");
            })
            if (!el.classList.contains("dictateTo")) {
                el.classList.add("dictateTo");
                if (scrollIntoView) {
                    el.scrollIntoView({ behavior: "smooth" });

                    var textarea = document.getElementById(id + "Textarea");
                    if (textarea) {
                        // NOTE: if you focused your developer tools, e.g. when using fakeHypothesis it won't set your cursor in that textarea.
                        textarea.focus();
                    }
                }
            }
        }

        if (id === ELEMENT_ID.technology
            || id === ELEMENT_ID.medicalHistory
            || id === ELEMENT_ID.remarks
            || id === ELEMENT_ID.summary
            || id === ELEMENT_ID.none
        ) {
            setAsrToUse("freeText");
        }
        else if (id === ELEMENT_ID.findings
            || id === ELEMENT_ID.zones
            || id === ELEMENT_ID.prostateVolume
            || id === ELEMENT_ID.lesions
            || id === ELEMENT_ID.psa
        ) {
            setAsrToUse("formText");
        }

        setAsrFocus(id);
    }

    function onHypothesis(response: any) {
        let message: any = JSON.parse(response.data);
        var hypothesisString = hypothesisTokenToString(message.hypothesis);
        if (message.is_final) {
            if (speechCommandProvider.tryRunSpeechCommand(hypothesisString) === false) {
                // speech command not recognized / did not ran:
                setHypothesis(hypothesisString);
                console.log("asrFocusRef.current:", asrFocusRef.current);
                if (asrFocusRef.current === ELEMENT_ID.technology) {
                    dictate(hypothesisString, setTechnology);
                }
                else if (asrFocusRef.current === ELEMENT_ID.medicalHistory) {
                    dictate(hypothesisString, setMedicalHistory);
                }
                else if (asrFocusRef.current === ELEMENT_ID.findings ||
                    asrFocusRef.current === ELEMENT_ID.prostateVolume ||
                    asrFocusRef.current === ELEMENT_ID.lesions ||
                    asrFocusRef.current === ELEMENT_ID.psa) {

                    console.log("Getting nlu response for lesion form...");
                    getNLUFormsData(hypothesisString, sessionIdRef, setSessionId).then((nluResponse: any) => {
                        if (!nluResponse.hasOwnProperty("no_content")) {
                            let formData = nluResponse.formData;
                            if (nluResponse.contextData) {
                                if (nluResponse.contextData.context_slots) {
                                    if (nluResponse.contextData.context_slots.current_lesion_num) {
                                        var currentLesionNum = nluResponse.contextData.context_slots.current_lesion_num;
                                        console.log("Lesion number mentioned in speech: ", currentLesionNum - 1);
                                        setFocusLesion(currentLesionNum - 1);
                                    }
                                }
                            }

                            setHypothesis(markEntities(nluResponse));
                            setProstateData({ ...prostateDataRef.current, ...extractProstateData(formData) });
                            let extractedLesionList = extractLesionsData(formData, lesionListRef.current);
                            let maxPirads;
                            for (let j = 0; j < extractedLesionList.lesions.length; j++) {
                                let currentPirads = extractedLesionList.lesions[j].pirads
                                if (maxPirads === undefined || maxPirads < parseInt(currentPirads)) {
                                    maxPirads = currentPirads;
                                }
                            }
                            if (maxPirads) {
                                setPIRADS(maxPirads);
                            }
                            // if PIRADS was given by speech
                            console.log("FORMDATA", formData);
                            if (formData.PIRADS) {
                                setPIRADS(formData.PIRADS);
                            }
                            if (!_.isEqual(lesionListRef.current.lesions, extractedLesionList)) {
                                setLesionList(extractedLesionList);
                            }
                        }
                    })
                }
                else if (asrFocusRef.current === ELEMENT_ID.remarks) {
                    dictate(hypothesisString, setRemarks);
                }
                else if (asrFocusRef.current === ELEMENT_ID.summary) {
                    dictate(hypothesisString, setSummary);
                }
                else {
                    console.warn("No input for ASR/NLU specified.");
                    // TODO: show info for navigation with speech
                }

            } else {
                setHypothesis(hypothesisString)
            }
        }
        else if (message.is_final === false) {
            setHypothesis(hypothesisString);
        }
    }

    (window as any).fakeHypothesis = (fakeHypothesis: string) => {
        var tokens = fakeHypothesis.split(" ");
        var tokens_dict = tokens.map((content) => {return {"content": content}});
        onHypothesis(
        { data: `{"hypothesis": ${JSON.stringify(tokens_dict)}, "is_final": true}` }
    )};

    const handleCursorPositionChange = (textAreaRef:any) => {
        if (textAreaRef && textAreaRef.current) {
            const position = textAreaRef.current.selectionStart;
            setTextAreaCursorPosition(position);
            textAreaCursorPositionRef.current = position;
        }
    };

    useEffect(() => {
        setAsrState(ASRSTATE.connecting);
        if (speechCommandsCreated === false) {
            speechCommandProvider.addSpeechCommand(["Befund", "Zusammenfassung"], () => { setFocus(ELEMENT_ID.summary, true) });
            speechCommandProvider.addSpeechCommand(["Technik"], () => { setFocus(ELEMENT_ID.technology, true) });
            speechCommandProvider.addSpeechCommand(["Indikation", "Anamnese"], () => { setFocus(ELEMENT_ID.medicalHistory, true) });
            speechCommandProvider.addSpeechCommand(["Formular", "PIRADS", "PI-RADS", "Piraten", "PIRADS Klassifizierung", "Piraten Klassifizierung", "Läsionen", "PSA", "Prostata"], () => { setFocus(ELEMENT_ID.findings, true) });
            speechCommandProvider.addSpeechCommand(["Anmerkungen"], () => { setFocus(ELEMENT_ID.remarks, true) });
            speechCommandProvider.addSpeechCommand(["Prostata", "Prostata Volumen", "Prostatavolumen", "PSA"], () => { setFocus(ELEMENT_ID.prostateVolume, true) });
            speechCommandProvider.addSpeechCommand(["Läsionen"], () => { setFocus(ELEMENT_ID.lesions, true) });
            setSpeechCommandsCreated(true);
        }
    }, [])

    useEffect(() => {
        switch (asrState) {
            case (ASRSTATE.connecting):
                asrFreeText.connectToASR((e: any) => { console.log("freeText", e); onHypothesis(e); }, asrState, setAsrState, false, (e: any) => { });
                asrFormText.connectToASR((e: any) => { console.log("formText", e); onHypothesis(e); }, asrState, setAsrState, false, (e: any) => { });
                break;
            case ASRSTATE.disconnecting:
                asrFreeText.stopSpeechRecognition(setAsrState);
                asrFormText.stopSpeechRecognition(setAsrState);
                break;
            case ASRSTATE.unmute:
                if (asrToUseRef.current === "freeText") {
                    asrFreeText.unmute(setAsrState);
                    asrFormText.mute(asrState, setAsrState);
                } else if (asrToUseRef.current === "formText") {
                    asrFormText.unmute(setAsrState);
                    asrFreeText.mute(asrState, setAsrState);
                }
                break;
            case ASRSTATE.mute:
                asrFreeText.mute(asrState, setAsrState);
                asrFormText.mute(asrState, setAsrState);
                break;
        }
    }, [asrState])

    async function getNLUFormsData(hypothesisString: string, sessionIdRef: any, setSessionId: any) {
        // TODO: replace mockup with actual NLU when it is ready
        var useMockup = false;
        if (useMockup) {
            return {
                "prostate": {
                    "length": 2,
                    "width": 2,
                    "height": 2,
                    "volume": 8
                },
                "psa": {
                    "value": 0,
                    "density": 0
                },
                "descriptions": {
                    "TZa": "Transitionalzone anterior: Läsion eins befindet sich mittig rechts"
                },
                "lesions": [
                    {
                        "lesionNum": 1,
                        "location": { "level": "Mittig", "side": "rechts", "zone": "Tza" }
                    }
                ]
            }
        }
        else {
            console.log("FETCHING NLU DATA")
            console.log(lesionListRef)
            const updateFormData = buildFormData();
            console.log(updateFormData)
            return getNLUResponse(hypothesisString, updateFormData, sessionIdRef.current, CONFIG.nlu.auth, CONFIG.nlu.url, CONFIG.nlu.nluConfig)
                .then(async (response: any) => {
                    if (response && response.status === 201) {
                        let responseData = await response.json();
                        if (responseData !== "0") {
                            setSessionId(responseData.sessionId);
                        }
                        console.log(responseData.formData)
                        // setActiveLesion(responseData.contextData.context_slots.current_lesion_num);
                        return responseData;
                    }
                    return undefined;
                });
        }
    }

    /**
     * Build formdata from current states
     * @returns 
     */
    function buildFormData() {
        return {
            "lesions": lesionListRef.current.lesions.map(lesion => {
                const { zone, side, level, ...rest } = lesion;
                const location = { zone, side, level };
                return { ...rest, location };
            }),
            "prostate": prostateDataRef.current["prostate"],
            "psa": prostateDataRef.current["psa"],
            "PIRADS": PIRADSRef.current,
            "current_lesion_num": focusLesion+1,
        }
    }

    function setFocusLesionManual(index: any) {
        setFocusLesion(index);
        const updateFormData = buildFormData();
        getNLUResponse(`Läsion ${index+1}`, updateFormData, sessionIdRef.current, CONFIG.nlu.auth, CONFIG.nlu.url, CONFIG.nlu.nluConfig)
            .then(async (response: any) => {
                    if (response && response.status === 201) {
                        let responseData = await response.json();
                        if (responseData !== "0") {
                            setSessionId(responseData.sessionId);
                        }
                    }
            });
    }

    return (
        <AppStateProvider>
            <ASRButton currentState={asrState} setState={setAsrState}></ASRButton>
            <HypothesisTextarea hypothesis={hypothesis} setHypothesis={setHypothesis} state={asrState}></HypothesisTextarea>
            <Header logoPath="/alphaspeech_speech_to_form.png" designElementPath="/Wellengrafik_Header.png"/>
            <div id="form">
                <div style={{ "border": "none", "padding": "20px 5rem", "background": "white", "maxWidth": "1260px", "margin": "auto" }}>
                    <DictateWithTemplate
                        divID={ELEMENT_ID.technology}
                        header="Technik"
                        category="technology"
                        state={technology}
                        setState={setTechnology}
                        setAsrFocus={setFocus}
                        handleCursorPositionChange={handleCursorPositionChange}
                    ></DictateWithTemplate>
                    <DictateWithTemplate
                        divID={ELEMENT_ID.medicalHistory}
                        header="Anamnese, klinische Angaben und rechtfertigende Indikation"
                        category="medicalHistory"
                        state={medicalHistory}
                        setState={setMedicalHistory}
                        setAsrFocus={setFocus}
                        handleCursorPositionChange={handleCursorPositionChange}
                    ></DictateWithTemplate>
                    <DictateWithTemplate
                        divID={ELEMENT_ID.summary}
                        category="summary"
                        state={summary}
                        setState={setSummary}
                        header="Befund"
                        setAsrFocus={setFocus}
                        handleCursorPositionChange={handleCursorPositionChange}
                    ></DictateWithTemplate>
                    <FindingArea {
                        ...{
                            divID: ELEMENT_ID.findings,
                            prostateData, setProstateData,
                            lesionList, setLesionList,
                            PIRADS, setPIRADS,
                            focusLesion, setFocusLesion: setFocusLesionManual,
                            setFocus,
                            canvasRef, doReset
                        }}></FindingArea>
                    <Dictate
                        divID={ELEMENT_ID.remarks}
                        data={remarks}
                        setData={setRemarks}
                        header="Anmerkungen"
                        setAsrFocus={setFocus}
                        handleCursorPositionChange={handleCursorPositionChange}></Dictate>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-3 mb-3'>
                                <button
                                    style={{backgroundColor: "#001534", "width": "-webkit-fill-available", fontSize: "calc(0.8rem + 0.1vw)" }}
                                    className='btn btn-secondary'
                                    onClick={() => {
                                        setSessionId("0"); // use new NLU session too

                                        setPIRADS("not defined");
                                        setProstateData(prostateDefaultData);
                                        setLesionList(lesionListDefaultData);
                                        setFocusLesion(0);
                                        lesionListRef.current = lesionList;

                                        setTechnology("");
                                        setMedicalHistory("");
                                        setSummary("");
                                        setRemarks("");

                                        setFocus(ELEMENT_ID.none, false)
                                        // trigger reset in nestles components, e.g. DrawableImage
                                        setDoReset(true);
                                        setTimeout(() => setDoReset(false), 0);
                                    }}
                                >ZURÜCKSETZEN</button>
                            </div>
                            <div className='col-md-3 ms-auto mb-3'>
                                <button disabled style={{backgroundColor: "#001534", "width": "-webkit-fill-available", fontSize: "calc(0.8rem + 0.1vw)"}} className='btn btn-secondary'>SPEICHERN</button>
                            </div>
                            <div className='col-md-3 mb-3'>
                                <ReportButton {
                                    ...{
                                        technology, setTechnology,
                                        medicalHistory, setMedicalHistory,
                                        summary, setSummary,
                                        remarks, setRemarks,
                                        prostateData, setProstateData,
                                        lesionList, setLesionList,
                                        PIRADS, setPIRADS,
                                        canvasRef
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppStateProvider>
    );
}

export default App;

