import React, { useState } from 'react';
import jsPDF from 'jspdf';
import Modal from 'react-modal';
import 'jspdf-autotable';
import Handlebars from 'handlebars';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';

Modal.setAppElement('#root');

function ReportButton(props: any) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [isPatientView, setIsPatientView] = useState(false)
  const contentFontSize = "12px";

  const toggleView = () => {
    setIsPatientView((prevState) => {
      let newState = !prevState;
      newState ? generatePDFPatientReport() : generatePDFReport()
      return newState;
    });
  };

  const generatePDFPatientReport = () => {
      const piRadsImage = props.canvasRef.current.toDataURL()

      const piradsDescriptions: any = {
        "1": "Das Vorliegen eines Prostatakarzinoms ist ausgeschlossen.",
        "2": "Das Vorliegen eines Prostatakarzinoms ist unwahrscheinlich.",
        "3": "Das Vorliegen eines Prostatakarzinoms ist zweifelhaft.",
        "4": "Das Vorliegen eines Prostatakarzinoms ist wahrscheinlich.",
        "5": "Das Vorliegen eines Prostatakarzinoms ist sehr wahrscheinlich."
      }

      const lesionListData = props.lesionList;

      var lesionCnt = 1
      var lesionTableHtml = `<table class="mt-3" style="font-size:${contentFontSize}">`;
      if (lesionListData && lesionListData["lesions"].length) {
        // Start the table HTML string
        
        // Add headers to the table
        lesionTableHtml += `
            <tr>
                <th>Nr.</th>
                <th>Zone</th>
                <th>Level</th>
                <th>Seite</th>
                <th>PI-RADS</th>
                <th>Beschreibung</th>
            </tr>`;
        
        // Iterate over each lesion and populate the table
        lesionListData["lesions"].forEach((lesion: any) => {
            let descr = piradsDescriptions[String(lesion.pirads)];
            
            lesionTableHtml += `
                <tr>
                    <td>${lesionCnt}</td>
                    <td>${lesion.zone ?? '-'}</td>
                    <td>${lesion.level ?? '-'}</td>
                    <td>${lesion.side ?? '-'}</td>
                    <td>${lesion.pirads ?? '-'}</td>
                    <td>${descr ?? '-'}</td>
                </tr>`;
            
            lesionCnt++;
        });
        
        // Close the table tag
        lesionTableHtml += '</table>';
        
      }
      else {
        lesionTableHtml = `<p>Es wurden keine Daten zu Läsionen in ihrem Befund aufgenommen.</p>`
      }

      const templateSource = `
          <div class="template-container">
              <h1>Patientenansicht</h1>
              <h2>Anamnese</h2>
              <p class="doctor-note-content">${props.medicalHistory.split('\n').join('<br>')}</p>

              <h2>Befund</h2>

              <h3>Prostata-Größe</h3>
              <p class="informative-content">Die Prostata – auch Vorsteherdrüse genannt – gehört zu den inneren Geschlechtsorganen des Mannes. Die durchschnittliche Größe der Prostata entspricht der einer Kastanie (3 cm lang, 4 cm breit und 2 cm dick) und hat einem Volumen von 20-25 ml. Sie vergrößert sich mit zunehmendem Alter stetig. Die gutartige Prostatavergrößerung (benigne Prostatahyperplasie, abgekürzt BPH) ist eine Erkrankung des Mannes im höheren Alter. Bei 60-Jährigen Männern misst die Prostata etwa 30 ml, bei über 75-jährigen Männern fast 40 ml. Von einer gutartigen Prostatavergrößerung sprechen Fachleute ab einem Volumen von über 30 ml.</p>
              <p class="patient-data-content">Ihre Prostata-Abmessungen: {{prostateData.prostate.length}} cm x {{prostateData.prostate.width}} cm x {{prostateData.prostate.height}} cm.</p>
              <p class="patient-data-content">Ihr Prostata-Volumen: {{prostateData.prostate.volume}} ml.</p>


              <div class="columns-container">
                  <h3>PSA</h3>
                  <div class="container-left-col">
                    <p class="informative-content">Der PSA-Test dient der Früherkennung von Prostatakrebs. Mit dem Test wird die Menge an „Prostata-spezifischem Antigen“ (PSA) im Blut bestimmt. PSA ist ein Eiweiß, das in der Prostata gebildet wird, und das ständig in kleinen Mengen ins Blut abgegeben wird. Ein erhöhter PSA-Wert kann auf Prostatakrebs hindeuten – er kann aber auch viele andere Ursachen haben. Folgende altersabhängige PSA-Werte gelten als unauffällig:</p>
                  </div>
                  <div class="container-right-col">
                      <table>
                          <tr>
                            <th>40-49 Jahre</th>
                            <td>2,5 ng/ml</td>
                          </tr>
                          <tr>
                            <th>50-59 Jahre</th>
                            <td>3,0 ng/ml</td>
                          </tr>
                          <tr>
                            <th>60-69 Jahre</th>
                            <td>4,0 ng/ml</td>
                          </tr>
                          <tr>
                            <th>über 70 Jahre</th>
                            <td>5 ng/ml</td>
                          </tr>
                      </table>
                  </div>
              </div>
              <p class="patient-data-content">Ihr PSA-Wert: {{prostateData.psa.value}} ng/ml.</p>

              <div class="columns-container mb-3" style="page-break-before: always;">
                  <h3>Läsionen</h3>
                  <img src="${piRadsImage}" style="max-width: 50%; height: auto;">
                  ${lesionTableHtml}
              </div>

              <p class="doctor-note-content">${props.summary.split('\n').join('<br>')}</p>

              <h2>Anmerkungen</h2>
              <p class="doctor-note-content">${props.remarks.split('\n').join('<br>')}</p>

              <div>
                <h2 class="mt-3">Fazit</h2>
                <p class="boxed-content"><b>PI-RADS</b> Klassifizierung {{PIRADS}}: ${piradsDescriptions[String(props.PIRADS)]}</p>
              </div>
          </div>
      `;

    const template = Handlebars.compile(templateSource);
    const filledTemplate = template(props);

    const container = document.createElement('div');
    container.innerHTML = filledTemplate;

    // Styling
    const style = document.createElement('style');
    style.textContent = `
      body {
          font-family: Arial, sans-serif;
      }
      h2 {
        padding-top: 20px;
      }
      h3 {
        font-size: 16px;
      }
      p {
        font-size: 12px;
      }
      .container-left-col {
        display: flex;
        float:left;
        max-width: 50%;
      }
      .container-right-col {
        padding-left: 25px;
        display: flex;
        float:right;
        max-width: 50%;
      }
      .columns-container {
        width: 100%;
        height: auto;
        overflow: hidden;
      }
      .boxed-content {
        border: 2px solid;
        background-color: #fff0bf;
        padding: 15px;
      }
      .informative-content {
        background-color: #bff0ff;
        padding: 15px;
        border-radius: 2px;
      }
      .patient-data-content {
        background-color: #f0f0f0;
        padding: 5px;
        border-radius: 2px;
      }
      .doctor-note-content {
        background-color: #ffebb3;
        padding: 5px;
        border-radius: 2px;
      }
    `;
    container.appendChild(style);

    document.body.appendChild(container);

    const options = {
      margin: 1,
      filename: 'report.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    html2pdf().from(container).set(options).toPdf().output('blob').then((pdfData:any) => {
      const url = URL.createObjectURL(pdfData);
      setPdfUrl(url);
      setModalIsOpen(true);

      document.body.removeChild(container); // Clean up the temporary element
    });

  }

//   const generatePDFPatientReport = () => {
//       const doc = new jsPDF();
//
//       // fontsize
//       const contentFontSize = 12;
//       const headerFontSize = 18;
//       const subHeaderFontSize = 14;
//
//       // spacing
//       const lineHeight = headerFontSize * 0.6;
//       const lineHeightContent = contentFontSize * 0.5;
//       const lineHeightTable = lineHeightContent * 1.3;
//       const margin = 10;
//       const marginTopBottom = 20;
//       const pageWidth = doc.internal.pageSize.getWidth();
//       const pageHeight = doc.internal.pageSize.getHeight();
//       const maxLineWidth = pageWidth - 2 * margin;
//       var yPosition = marginTopBottom;
//
//       const adjustYPositionBy = (additionalHeight: number) => {
//         if (yPosition + additionalHeight > pageHeight - marginTopBottom) {
//             doc.addPage();
//             yPosition = marginTopBottom + ((yPosition + additionalHeight) - (pageHeight - marginTopBottom));
//         }
//         else {
//             yPosition += additionalHeight;
//         }
//       };
//
//       const addHeadingAndText = (heading: string, text: string) => {
//         doc.setFontSize(headerFontSize);
//         const wrappedHeader = doc.splitTextToSize(heading, maxLineWidth);
//         doc.text(wrappedHeader, margin, yPosition);
//         adjustYPositionBy(wrappedHeader.length * lineHeight)
//         doc.setFontSize(contentFontSize);
//         const wrappedContent = doc.splitTextToSize(text, maxLineWidth);
//         doc.text(wrappedContent, margin, yPosition);
//         adjustYPositionBy(lineHeight + (wrappedContent.length * lineHeightContent))
//       }
//
//       if (props) {
//         // ANAMNESE
//         addHeadingAndText("Anamnese", props.medicalHistory);
//
//         // BEFUND
//         befund_text = ""
//         prostate1 = `Ihre Prostata-Abmessungen: ${props.prostateData.prostate.length} cm x ${props.prostateData.prostate.width} cm x ${props.prostateData.prostate.height} cm`
//         prostate2= `Ihr Prostata-Volumen: ${props.prostateData.prostate.volume} ml`
//         psa1 = `Ihr PSA-Wert: ${props.prostateData.psa.value} ng/ml`
//         addHeadingAndText("Befund", props.medicalHistory);
//
//         // LÄSIONEN
//         // image
//         (doc as any).addImage(piRadsImage, 'png', 10, 78, 100, 120);
//         adjustYPositionBy(120);
//
//         // SUMMARY
//         addHeadingAndText("Zusammenfassung", props.summary);
//       }
//
//       // Generate the PDF blob and URL
//       const pdfData = doc.output('blob');
//       const url = URL.createObjectURL(pdfData);
//       setPdfUrl(url);
//       setModalIsOpen(true);
//   }

  const generatePDFReport = () => {
    const piRadsImage = props.canvasRef.current.toDataURL()
    const doc = new jsPDF();

    // fontsize
    const contentFontSize = 12;
    const headerFontSize = 18;
    const subHeaderFontSize = 14;

    // spacing
    const lineHeight = headerFontSize * 0.6;
    const lineHeightContent = contentFontSize * 0.5;
    const lineHeightTable = lineHeightContent * 1.3;
    const margin = 10;
    const marginTopBottom = 20;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const maxLineWidth = pageWidth - 2 * margin;
    var yPosition = marginTopBottom;

    const adjustYPositionBy = (additionalHeight: number) => {
        if (yPosition + additionalHeight > pageHeight - marginTopBottom) {
            doc.addPage();
            yPosition = marginTopBottom ;//+ ((yPosition + additionalHeight) - (pageHeight - marginTopBottom));
        }
        else {
            yPosition += additionalHeight;
        }
    };

    const addHeadingAndText = (heading: string, text: string) => {
        doc.setFontSize(headerFontSize);
        const wrappedHeader = doc.splitTextToSize(heading, maxLineWidth);
        doc.text(wrappedHeader, margin, yPosition);
        adjustYPositionBy(wrappedHeader.length * lineHeight)
        doc.setFontSize(contentFontSize);
        const wrappedContent = doc.splitTextToSize(text, maxLineWidth);
        doc.text(wrappedContent, margin, yPosition);
        adjustYPositionBy(lineHeight + (wrappedContent.length * lineHeightContent))
    }

    // data
    const formDiv = document.getElementById('form');
    var contentDiv;

    if (formDiv && formDiv.firstElementChild) {
      contentDiv = formDiv.firstElementChild;
    }
    else {
        return;
    }

    if (props) {

        addHeadingAndText("Technik", props.technology);
        addHeadingAndText("Anamnese", props.medicalHistory);

        doc.setFontSize(headerFontSize);
        const wrappedSubHeader = doc.splitTextToSize("Befund", maxLineWidth);
        doc.text(wrappedSubHeader, margin, yPosition);
        adjustYPositionBy(wrappedSubHeader.length * lineHeight)

        // // ZONES
        // const zones = props.zones;
        // if (zones) {
        //     doc.setFontSize(contentFontSize);
        //     const wrappedContent = doc.splitTextToSize(zones, maxLineWidth);
        //     doc.text(wrappedContent, margin, yPosition);
        //     adjustYPositionBy(wrappedContent.length * lineHeight)
        // }

        // PROSTATE & PSA
        const prostateData = props.prostateData.prostate;
        const psaData = props.prostateData.psa;
        if (prostateData && psaData) {
            doc.setFontSize(subHeaderFontSize);
            const wrappedHeader = doc.splitTextToSize("Prostata und PSA", maxLineWidth);
            doc.text(wrappedHeader, margin, yPosition);
            adjustYPositionBy(wrappedHeader.length * lineHeight)
            doc.setFontSize(contentFontSize);
            let table: any[] = [];

            table.push([
                prostateData.length + " cm",
                prostateData.width + " cm" || '',
                prostateData.height + " cm" || '',
                prostateData.volume + " ml" || '',
                psaData.value|| '',
                psaData.density + " ng/ml" || '',
            ]);

            (doc as any).autoTable({
              startY: yPosition,
              head: [['L', 'B', 'H', 'Vol', 'PSA-Wert', 'PSA-Dichte']],
              body: table,
            });
            adjustYPositionBy(lineHeight + ((table.length+1)*lineHeightTable))
        }

        // LESIONS
        const lesionListData = props.lesionList;
        if (lesionListData) {
            doc.setFontSize(subHeaderFontSize);
            const wrappedHeader = doc.splitTextToSize("Läsionen", maxLineWidth);
            doc.text(wrappedHeader, margin, yPosition);
            adjustYPositionBy(wrappedHeader.length * lineHeight)
            doc.setFontSize(contentFontSize);
            let table: any[] = []
            lesionListData["lesions"].forEach((lesion:any) => {

                table.push([
                    `Läsion ${lesion.lesionNum}`,
                    lesion.zone || '',
                    lesion.level || '',
                    lesion.side || '',
                    lesion.size + " cm" || '',
                    lesion.t2 || '',
                    lesion.dwi || '',
                    lesion.dce || '',
                    lesion.pirads || '',
                ]);
            });

            (doc as any).autoTable({
              startY: yPosition,
              head: [['Läsion', 'Zone', 'Loc', 'Side', 'Größe', 'T2', 'DWI', 'DCE', 'PI-RADS']],
              body: table,
            });
            adjustYPositionBy(lineHeight + ((table.length+1)*lineHeightTable))
        }
        addHeadingAndText("Anmerkungen", props.remarks);

        addHeadingAndText("Zusammenfassung", props.summary);

        // IMAGE
        doc.addPage();
        var yPosition = marginTopBottom;

        const pageWidth = doc.internal.pageSize.width;
        const originalWidth = piRadsImage.width;
        const originalHeight = piRadsImage.height;
        let targetWidth = originalWidth;
        let targetHeight = originalHeight;
        if (originalWidth > doc.internal.pageSize.width) {
            targetWidth = doc.internal.pageSize.width;
            targetHeight = (originalHeight / originalWidth) * targetWidth;
        }
        if (targetHeight > doc.internal.pageSize.height) {
            targetHeight = doc.internal.pageSize.height;
            targetWidth = (originalWidth / originalHeight) * targetHeight;
        }
        addHeadingAndText("Abbildung der Läsion(en)", "");
        {(doc as any).addImage(piRadsImage, 'png', 10, yPosition, targetWidth, targetHeight);} // 10, 78,
        adjustYPositionBy(targetHeight);
    }

    // Generate the PDF blob and URL
    const pdfData = doc.output('blob');
    const url = URL.createObjectURL(pdfData);
    setPdfUrl(url);
    setModalIsOpen(true);

  };



  return (
    <div>
      <button style={{backgroundColor: "#001534", "width": "-webkit-fill-available", fontSize: "calc(0.8rem + 0.1vw)" }} className='btn btn-secondary'  onClick={isPatientView ? generatePDFPatientReport : generatePDFReport}>EXPORTIEREN</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="PDF Preview"
      >
        <iframe src={pdfUrl} width="100%" height="600px" title="PDF Preview" />
        <div style={{ display: 'flex', gap: '5px' }}>
            <button
                onClick={() => window.open(pdfUrl)}>Save/Print PDF</button>
            <button
                onClick={toggleView}>{isPatientView ? 'Arztansicht' : 'Patientenansicht'}</button>
            <button
                onClick={() => setModalIsOpen(false)}>Close</button>
        </div>
      </Modal>
    </div>
  );
}

export default ReportButton;
