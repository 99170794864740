import React from 'react';

function Header({logoPath, designElementPath, title}: any) {
    return (
        <React.Fragment>
            <header
              id="header"
              style={{ '--header-after-bg': `url(${designElementPath})` as string,}}>
                <div id="top_bar">
                    <div className="header-content">
                        <a className="header-content" href="/" title={title}>
                            <img className="header-content" src={logoPath} style={{maxHeight: 48}}
                                data-retina={logoPath} alt="alphaspeech" data-no-retina />
                        </a>
                    </div>
                </div>
                <img id="header-design-element" src={designElementPath} data-retina={designElementPath} alt="alphaspeech" data-no-retina />
            </header>
        </React.Fragment>
    );
};

export { Header };
