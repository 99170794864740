import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DvbApp from './dvb/App';
import DBApp from './db/App';
import PiradsApp from './pi_rads/App';
import IndexApp from './common/App';
import DataViewer from './viewer/DataViewer';
import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const VERSION = "0.1.0";
console.log(VERSION);

root.render(
    <Router>
      <Routes>
        <Route path="/" element={<IndexApp/>} />
        <Route path="/dvb" element={<DvbApp/>} />
        <Route path="/db" element={<DBApp/>} />
        <Route path="/pirads" element={<PiradsApp/>} />
        <Route path="/viewer" element={<DataViewer/>} />
      </Routes>
    </Router>
)
